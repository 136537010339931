import { combineReducers } from 'redux'

// Core API Slice
import { apiSlice } from 'src/api/apiSlice'

// Split Reducers
import { currentUserReducer } from 'src/features/current-user/userSlice'
import { appsApiSlice } from 'src/features/apps/appsSlice'

export const rootReducer = combineReducers({
  currentUser: currentUserReducer,
  apps: appsApiSlice,
  [apiSlice.reducerPath]: apiSlice.reducer
})
