import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiSlice } from 'src/api/apiSlice'

export const currentUser = createAsyncThunk(
  'currentUser/fetch',
  async () => {
    const response = await axios.get(`${process.env.GATSBY_BACKEND_URL}/api/users/me`, { withCredentials: true })

    const loggedIn = response.status === 200

    return {
      loggedIn,
      userInfo: loggedIn ? response.data.resource : {}
    }
  }
)

export const logoutUser = createAsyncThunk(
  'currentUser/delete',
  async () => {
    const response = await axios.delete(`${process.env.GATSBY_BACKEND_URL}/logout`, { withCredentials: true })
    window.location.href = response.data.redirect
  }
)

const transformUserResponse = (response) => ({
  email: response?.resource.email,
  name: response?.resource.name,
  uri: response?.resource.uri,
  teamUuid: response?.resource.team_uuid
})

export const userApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query({
      query: () => ({ url: `${process.env.GATSBY_BACKEND_URL}/api/users/me`, credentials: 'include' }),
      transformResponse: transformUserResponse,
      providesTags: ['User']
    }),
    extraOptions: {
      maxRetries: 1
    }
  })
})

export const { useGetUserQuery } = userApiSlice

const userSlice = createSlice({
  name: 'currentUser',
  initialState: {
    status: null,
    loggedIn: false,
    userInfo: {}
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(currentUser.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(currentUser.fulfilled, (state, action) => {
      state.status = 'loaded'
      state.loggedIn = action.payload.loggedIn
      state.userInfo = action.payload.userInfo
    })
    builder.addCase(currentUser.rejected, (state) => {
      state.status = 'failed'
      state.loggedIn = false
      state.userInfo = {}
    })
    builder.addCase(logoutUser.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(logoutUser.fulfilled, (state) => {
      state.status = 'loaded'
      state.loggedIn = false
    })
    builder.addCase(logoutUser.rejected, (state) => {
      state.status = 'failed'
      state.loggedIn = true
    })
  }
})

export const { reducer: currentUserReducer } = userSlice
