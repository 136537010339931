import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import { Helmet } from 'react-helmet'

import calendlyLogo from 'src/assets/images/OgImage.png'

import { ContentfulMeta } from './layout/index'

export const Head = (props: { meta: ContentfulMeta }) => {
  const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                    description
                }
            }
        }
	`)

  const { site } = data

  const { meta } = props

  const defaultImage = {
    src: calendlyLogo,
    height: 630,
    width: 1200
  }

  const pageTitle = meta?.pageTitle || site.siteMetadata.title
  const metaDescription = meta?.metaDescription || site.siteMetadata.description

  const ogTitleTag = meta?.ogTitle || pageTitle
  const ogDescriptionTag = meta?.ogDescription || metaDescription

  const twitterTitleTag = meta?.twitterTitle || ogTitleTag
  const twitterDescriptionTag = meta?.twitterDescription || ogDescriptionTag

  const ogImageTag = meta?.ogImage?.file.url ? `https:${meta?.ogImage?.file.url}` : defaultImage.src
  const twitterImageTag = meta?.twitterImage?.file.url ? `https:${meta?.twitterImage?.file.url}` : ogImageTag

  return (
    <Helmet>
      <html lang="en" />
      <link rel="preload" as="script" href="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js" />
      <script
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        data-document-language="true"
        data-domain-script={process.env.GATSBY_COOKIELAW_DOMAIN_SCRIPT}
        type="text/javascript"
        async
      />
      <script type="text/javascript">
        {`
            function OptanonWrapper() {}
          `}
      </script>

      <title>{pageTitle}</title>
      <meta name="description" content={metaDescription} />
      <meta name="application-name" content={site.siteMetadata.title} />

      {/* Facebook Tags */}
      <meta property="og:site_name" content={site.siteMetadata.title} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={ogTitleTag} />
      <meta property="og:description" content={ogDescriptionTag} />
      <meta property="og:image" content={ogImageTag} />

      {/* Twitter tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={site.siteMetadata.title} />
      <meta property="twitter:title" content={twitterTitleTag} />
      <meta property="twitter:description" content={twitterDescriptionTag} />
      <meta name="twitter:image" content={twitterImageTag} />

      {/* Icon tags  */}
      <meta name="msapplication-TileColor" content="#ffffff" />

    </Helmet>
  )
}
