import React from 'react'

import 'src/styles/reset.css'
import '@stoplight/elements-dev-portal/styles.min.css'
import 'src/styles/stoplight.css'
import 'src/styles/base.css'

// Layout
import AirbrakeErrorBoundary from 'src/components/airbrake_error_boundary'

// Meta
import { Head } from '../head'

// Context
import CollapseSidebarProvider from '../../context/CollapseSidebar'

export type ContentfulMeta = {
    identifier: string,
    metaDescription?: string,
    pageTitle?: string,
    ogTitle?: string,
    ogDescription?: string,
    ogImage?: {
      file: {
        url: string,
      }
    },
    twitterTitle?: string,
    twitterDescription?: string,
    twitterImage?: {
      file: {
        url: string,
      }
    },
}

const Layout: React.FC<{
  data: {
    contentfulPage: {
      meta: ContentfulMeta
    }
  },
  pageContext: any
}> = (props) => {
  const { children, pageContext } = props
  if (pageContext.layout === 'app-layout') {
    return (
      <div>
        {children}
      </div>
    )
  }

  const { data: { contentfulPage } } = props

  return (
    <AirbrakeErrorBoundary>
      <Head meta={contentfulPage?.meta} />
      <CollapseSidebarProvider>
        {children}
      </CollapseSidebarProvider>
    </AirbrakeErrorBoundary>
  )
}

export default Layout
