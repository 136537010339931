import { apiSlice } from 'src/api/apiSlice'
import formatApp from '../../util/formatApp'

const transformAppsResponse = (response) => ({
  myApps: response.collection.map((app) => formatApp(app))
})

const onQueryStartedUnauthError = async (args, { queryFulfilled }) => {
  try {
    await queryFulfilled
  } catch (error) {
    if (error.error.originalStatus === 401) {
      window.location.assign('/')
    }
  }
}

export const appsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getApps: builder.query({
      query: () => ({ url: `${process.env.GATSBY_BACKEND_URL}/api/oauth_apps` }),
      transformResponse: transformAppsResponse,
      providesTags: (result, error, arg) =>
        (result
          // eslint-disable-next-line
          ? [...result.myApps.map(({ clientId }) => ({ type: 'App', id: clientId })), 'App']
          : ['App'])
    }),
    createApp: builder.mutation({
      query: (body) => (
        { url: `${process.env.GATSBY_BACKEND_URL}/api/oauth_apps`, method: 'POST', body: formatApp(body) }),
      invalidatesTags: (result, error, arg) => [{ type: 'App', id: arg.clientId }],
      onQueryStarted: onQueryStartedUnauthError
    }),
    updateApp: builder.mutation({
      query: (body) => ({ url: `${process.env.GATSBY_BACKEND_URL}/api/oauth_apps/${body.clientId}`, method: 'PATCH', body: formatApp(body) }),
      invalidatesTags: (result, error, arg) => [{ type: 'App', id: arg.clientId }],
      onQueryStarted: onQueryStartedUnauthError
    }),
    deleteAppById: builder.mutation({
      query(id) {
        return {
          url: `${process.env.GATSBY_BACKEND_URL}/api/oauth_apps/${id}`,
          method: 'DELETE'
        }
      },
      invalidatesTags: (result, error, arg) => [{ type: 'App', id: arg.id }],
      onQueryStarted: onQueryStartedUnauthError
    })
  })
})

export const {
  useGetAppsQuery,
  useCreateAppMutation,
  useUpdateAppMutation,
  useDeleteAppByIdMutation
} = appsApiSlice
