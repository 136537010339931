import React, { createContext, useState } from 'react'

export const SidebarContext = createContext([])

const CollapseSidebarProvider = (props) => {
  const {
    children
  } = props
  const [collapsedSidebar, toggleCollapseSidebar] = useState(false)

  return (
    <SidebarContext.Provider
      value={[collapsedSidebar, toggleCollapseSidebar]}
    >
      {children}
    </SidebarContext.Provider>
  )
}

export default CollapseSidebarProvider
