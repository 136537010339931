import { camelCase, snakeCase } from 'change-case'

function formatApp(app) {
  if (app === null || typeof app !== 'object') {
    return app
  }

  const result = {}
  for (const key in app) {
    if (key.includes('_')) {
      const camelCaseKey = camelCase(key)
      result[camelCaseKey] = formatApp(app[key])
    } else {
      const snakeCaseKey = snakeCase(key)
      result[snakeCaseKey] = formatApp(app[key])
    }
  }
  return result
}

export default formatApp
