module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/home/runner/work/dev-portal/dev-portal/src/components/layout/index.tsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"sU7rjm0zi05XgF4ty5FeqANufMkLliOr","devKey":"sU7rjm0zi05XgF4ty5FeqANufMkLliOr","trackPage":true,"manualLoad":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Calendly Developer","short_name":"Calendly Developer","description":"Free Online Appointment Scheduling Software - Calendly","start_url":"/","background_color":"#ffffff","theme_color":"#006BFF","display":"standalone","icon":"./src/assets/icons/icon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"562a6280871383fd65846bc6297cf964"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
