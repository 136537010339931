import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const apiSlice = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: '/',
    prepareHeaders(headers) {
      return headers
    },
    credentials: 'include'
  }),
  tagTypes: ['App', 'User'],
  endpoints: () => ({})
})
