import React from 'react'
import { Notifier } from '@airbrake/browser'
import PropTypes from 'prop-types'

class AirbrakeErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
    if (!process.env.AIRBRAKE_PROJECT_ID) return

    this.airbrake = new Notifier({
      projectId: process.env.AIRBRAKE_PROJECT_ID,
      projectKey: process.env.AIRBRAKE_PROJECT_KEY,
      environment: process.env.NODE_ENV
    })
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true })

    if (!this.airbrake) return

    this.airbrake.notify({
      error,
      params: { info }
    })
  }

  render() {
    const { hasError } = this.state
    const { children } = this.props
    if (hasError) {
      // Fallback UI
      return <h1>Rendering error.</h1>
    }
    return children
  }
}

AirbrakeErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired
}

const Dummy = ({ children }) => {
  return children
}

Dummy.propTypes = {
  children: PropTypes.node.isRequired
}

const buildRuntime = typeof window === 'undefined'
const errorBoundary = buildRuntime ? Dummy : AirbrakeErrorBoundary

export default errorBoundary
